<template>
    <div>
        <div class="demo-spin-article">
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                        $t("loanWriteOff.customerNameOrLoanNumber")
                    }}</label>
                    <Select
                        v-model="model.loan_id"
                        filterable
                        :remote-method="loadLoan"
                        clearable
                        :placeholder="
                            $t('loanWriteOff.searchCustomerNameOrLoanNumber')
                        "
                        :class="{
                            'ivu-form-item-error': errors.has('loan_id')
                        }"
                    >
                        <Option
                            v-for="(option, index) in loanDisbursements"
                            :value="option.loan_id"
                            :key="index"
                            :label="option.loan_number"
                        >
                            {{ option.loan_number }} |
                            {{ option.customer.customer_code }} |
                            {{ option.customer.customer_name_en }} |
                            {{ option.customer.customer_name_kh }} |
                            {{ option.customer.contact_no }}
                        </Option>
                    </Select>
                </div>
            </div>
            <div
                class="list-group-item list-group-item-action d-flex mb-3"
                v-if="loan"
            >
                <div class="flex-fill text-gray-600">
                    <div class="tw-flex tw-justify-between">
                        <div>
                            {{ $t("loanWriteOff.customerCode") }}:
                            <span class="tw-font-bold">
                                {{
                                    loan.customer
                                        ? loan.customer.commune_code
                                        : ""
                                }}
                            </span>
                        </div>
                        <div class="tw-font-bold">
                            {{
                                loan.loan_type
                                    ? loan.loan_type.loan_type_en
                                    : ""
                            }}
                        </div>
                    </div>
                    <div class="tw-flex tw-justify-between">
                        <div>
                            {{ $t("loanWriteOff.customerName") }}:
                            <span class="tw-font-bold">
                                {{
                                    loan.customer
                                        ? loan.customer.customer_name_en
                                        : ""
                                }}
                            </span>
                        </div>
                        <div class="text-gray-600">
                            {{ $t("loanWriteOff.loanAmount") }}:
                            <span class="tw-font-bold">
                                {{
                                    formatCurrencyWithCode(
                                        loan.loan_amount,
                                        loan.currency
                                    )
                                }}
                            </span>
                        </div>
                    </div>
                    <div class="tw-flex tw-justify-between">
                        <div>
                            {{ $t("loanWriteOff.contactNo") }}:
                            <span class="tw-font-bold">
                                {{
                                    loan.customer
                                        ? loan.customer.contact_no
                                        : ""
                                }}
                            </span>
                        </div>
                        <div>
                            {{ $t("loanWriteOff.outstandingAmount") }}:
                            <span class="tw-font-bold text-warning">
                                {{
                                    formatCurrencyWithCode(
                                        loan.outstanding_amount,
                                        loan.currency
                                    )
                                }}
                            </span>
                        </div>
                    </div>
                    <div class="tw-flex tw-justify-between">
                        <div>
                            {{ $t("loanWriteOff.address") }}:
                            <small>
                                {{
                                    loan.customer
                                        ? loan.customer.village
                                            ? loan.customer.village.name_en +
                                              ", "
                                            : ""
                                        : ""
                                }}
                                {{
                                    loan.customer
                                        ? loan.customer.commune
                                            ? loan.customer.commune.name_en +
                                              ", "
                                            : ""
                                        : ""
                                }}
                                {{
                                    loan.customer
                                        ? loan.customer.district
                                            ? loan.customer.district.name_en +
                                              ", "
                                            : ""
                                        : ""
                                }}
                                {{
                                    loan.customer
                                        ? loan.customer.province
                                            ? loan.customer.province.name_en +
                                              "."
                                            : "-"
                                        : "-"
                                }}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                        $t("loanWriteOff.writeOffType")
                    }}</label>
                    <select
                        ref="write_off_type_id"
                        v-model="model.write_off_type_id"
                        class="form-control"
                        :class="{
                            'is-invalid': errors.has('write_off_type_id')
                        }"
                    >
                        <option
                            :value="null"
                            disabled
                            selected
                            style="display: none;"
                            >{{ $t("select") }}</option
                        >
                        <option
                            v-for="(type, index) in writeOffTypes"
                            :key="index"
                            :value="type.write_off_type_id"
                            >{{ type.write_off_type }}</option
                        >
                    </select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('write_off_type_id')"
                    >
                        {{ errors.first("write_off_type_id") }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                        $t("loanWriteOff.reason")
                    }}</label>
                    <textarea
                        v-model="model.write_off_reason"
                        class="form-control"
                        rows="3"
                        :class="{
                            'is-invalid': errors.has('write_off_reason')
                        }"
                    ></textarea>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('write_off_reason')"
                    >
                        {{ errors.first("write_off_reason") }}
                    </div>
                </div>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t("cancel") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t("saveAddNew") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        :waiting="waiting"
                        :disabled="waiting_new"
                        @click.prevent="onSave"
                    >
                        {{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapGetters, mapState } from "vuex";
import { isEmpty, debounce } from "lodash";

export default {
    name: "loanWriteOffForm",
    data() {
        return {
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            loading: false,
            model: {
                loan_id: null,
                write_off_type_id: null,
                write_off_reason: null
            }
        };
    },
    computed: {
        ...mapState("creditOperation/loanWriteOff", [
            "edit_data",
            "writeOffTypes",
            "loanDisbursements"
        ]),
        ...mapGetters(["formatCurrencyWithCode"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        loan() {
            if (this.loanDisbursements.length > 0 && this.model.loan_id) {
                return this.loanDisbursements.find(
                    l => l.loan_id == this.model.loan_id
                );
            }
            return null;
        }
    },
    methods: {
        ...mapActions("creditOperation/loanWriteOff", [
            "getLoanDisbursement",
            "getWriteOffType"
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getWriteOffType();
            await this.getLoanDisbursement().finally(() => {
                this.loading = false;
            });
        },
        loadLoan: debounce(function(query) {
            this.loanLoading = true;
            this.getLoanDisbursement({ search: query }).finally(() => {
                this.loanLoading = false;
            });
        }, 1000),
        onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("creditOperation/loanWriteOff/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.clearInput();
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("creditOperation/loanWriteOff/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("creditOperation/loanWriteOff/update", {
                    id: this.edit_data.transaction_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        async setEditData() {
            this.loading = true;
            await this.fetchResource();
            if (!isEmpty(this.edit_data)) {
                this.model.loan_id = this.edit_data.loan_id;
                this.model.write_off_type_id = this.edit_data.write_off_type_id;
                this.model.write_off_reason = this.edit_data.write_off_reason;
            }
        },
        clearInput() {
            this.model.loan_id = null;
            this.model.write_off_type_id = null;
            this.model.write_off_reason = null;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LAON WRITE-OFF",
                desc: not.text
            });
        }
    }
};
</script>
